<template>
  <q-layout view="hhh lpr lfr">

    <q-header class="navbar">
      <q-toolbar class="bg-verde">
        <q-btn flat class="q-px-xs" color="white" icon="menu" @click="onDrawer" />
        <q-toolbar-title class="text-white text-center text-bold text-h4">
          {{ showTitle }}
        </q-toolbar-title>
        <q-btn v-show="isAdmin" flat class="q-px-xs" color="white" icon="fas fa-user-shield" @click="onAdmin" />
      </q-toolbar>
    </q-header>

    <q-drawer
      class="bg-verde"
      v-model="drawer"
      show-if-above
      mini-to-overlay
      :width="270"
      :breakpoint="100"
      :mini="miniState"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
    >
      <div class="row text-center justify-center" style="height: 135px" v-show="logoPerfil">
        <div v-if="!miniState">
          <div class="logo full-width">
            <q-avatar size="55px" color="white">
              <q-img
                src="../assets/L10-Logo.jpg"
              />
            </q-avatar>
          </div>
          <div class="enterprise full-width text-h6 text-bold text-white">
            L10 Imports
            <q-separator color="white" class="q-my-md q-mx-xl" />
          </div>
        </div>  
      </div>
      <div class="column flex justify-center full-height">
        <q-list padding>
          <q-item
            clickable
            v-ripple
            class="text-white q-py-sm"
            v-for="(menu, index) in menus"
            :key="index"
            @click="drawClick(menu.name)"
          >
            <q-item-section avatar>
                <q-icon :name="menu.icon">
                  <q-badge
                    v-if="menu.qtdBadge"
                    rounded
                    color="red-9"
                    class="text-white text-bold"
                    floating
                  >
                    {{ qtdPreSale }}
                  </q-badge>
                </q-icon>
            </q-item-section>
            <q-item-section side class="text-white captalize">
              {{ menu.label }}
            </q-item-section>
          </q-item>
        </q-list>
      </div>  
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { supabase } from '../supabase'
import Slug from '../helpers/Slug'

export default {
  data() {
    return {
      menus: [
        {icon: "home", name: "home", label: "Painel Gerencial", route: "/home", nReduz: 'Gerencial', qtdBadge: false},
        // {icon: "fas fa-cart-plus", name: "preSales", label: "Orçamentos", route: "/preSales", nReduz: 'Orçamentos', qtdBadge: true},
        {icon: "shopping_basket", name: "sells", label: "Vendas", route: "/sells", nReduz: 'Vendas', qtdBadge: false},
        {icon: "list_alt", name: "orders", label: "Pedidos", route: "/orders", nReduz: 'Pedidos', qtdBadge: false},
        {icon: "build_circle", name: "repair", label: "Ordens de Serviços", route: "/repair", nReduz: 'OS`s', qtdBadge: false},
        {icon: "groups", name: "nets", label: "Redes Sociais", route: "/nets", nReduz: 'Sociais', qtdBadge: false},
        {icon: "category", name: "warehouse", label: "Controle de Estoque", route: "/warehouse", nReduz: 'Estoque', qtdBadge: false},
        {icon: "assignment_ind", name: "clients", label: "Cadastro de Clientes", route: "/clients", nReduz: 'Clientes', qtdBadge: false},
        {icon: "receipt_long", name: "expenses", label: "Cadastro de Despesas", route: "/expenses", nReduz: 'Despesas', qtdBadge: false},
        // {icon: "far fa-image", name: "galery", label: "Galeria de Imagens", route: "/galery", nReduz: 'Galeria', qtdBadge: false},
        {icon: "settings", name: "settings", label: "Configurações", route: "/settings", nReduz: 'Configuração', qtdBadge: false},
        {icon: "logout", name: "logout", label: "Sair", route: "/", nReduz: 'Sair', qtdBadge: false},
      ],
      miniState: true,
      drawer: false,
      logoPerfil: false,
      qtdPreSale: 0,
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
    isAdmin() {
      const { email } = supabase.auth.user()
      
      if(
        email === 'wesleyribeiro123@gmail.com' ||
        email === 'gestaoexcel.oficial@gmail.com'
      ) {
        return true;
      } else {
        return false;
      }
    },
    showTitle() {
      let name;
      let route = this.$route.path;

      this.menus.forEach(menu => {
        if(menu.route === route) {
          if(this.isMobile) {
            name = menu.nReduz;
          } else {
            name = menu.label;
          }
        } else if (route === '/panelADM') {
          if(this.isMobile) {
            name = 'ADMIN'
          } else {
            name = 'ADMINISTRATIVO'
          }
        }
      });

      return name;
    },
  },
  methods: {
    drawClick(menu) {
      this.menus.forEach(async i => {
        if( i.name === menu ) {
          if( i.route !== this.$router.path ) {

            if(i.name === 'logout') {
              await supabase.auth.signOut();
              localStorage.removeItem('defaultColor');
              localStorage.removeItem('enterprise');
              localStorage.removeItem('recnoClient');
            }

            this.miniState = false;
            this.drawer = true;
            this.$router.push(i.route);
          }
        }
      });
    },
    onDrawer() {
      if(this.drawer === true) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
    onAdmin() {
      this.$router.push('/panelADM')
    }
  },
  async beforeCreate() {
    const enterprise = localStorage.getItem('enterprise')
    const slug = Slug(enterprise)

    const { count } = await supabase
      .from('GE_PRE_SALE')
      .select('RECNO', { count: 'exact' })
      .filter('GE_ENTERPRISE', 'eq', slug)
      .eq('GE_STATUS', 'PENDENTE')

    this.qtdPreSale = count;
  }
}
</script>
