function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

// function Slug(enterprise) {
export default function Slug(enterprise) {
  let text = String(enterprise).toLowerCase()
  text = replaceAll(text, 'ã', 'a')
  text = replaceAll(text, 'á', 'a')
  text = replaceAll(text, 'à', 'a')
  text = replaceAll(text, 'â', 'a')
  text = replaceAll(text, 'ã', 'a')
  text = replaceAll(text, 'é', 'e')
  text = replaceAll(text, 'ê', 'e')
  text = replaceAll(text, 'í', 'i')
  text = replaceAll(text, 'ó', 'o')
  text = replaceAll(text, 'ô', 'o')
  text = replaceAll(text, 'õ', 'o')
  text = replaceAll(text, '\\.', '_')
  text = replaceAll(text, '-', '_')
  text = replaceAll(text, ' ', '_')
  text = replaceAll(text, 'ç', 'c')
  text = replaceAll(text, '&', '_')
  text = replaceAll(text, "'", "_")

  // console.log(text);
  return text
}

// Slug("Wesley's C&A");