import { createStore } from 'vuex'
import Querys from './modules/Querys'
import Functions from './modules/Functions'

const modules = {
  Querys,
  Functions
}

export default createStore({
  strict: false,
  modules: modules
})
